import React, { useState, useRef, useEffect } from "react";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import About from "./components/About";
import Contacts from "./components/Contacts";
import SideMenu from "./components/SideMenu";
import Footer from "./components/Footer";
import Projects from "./components/Projects";
import Fade from 'react-reveal/Fade';
import useElementOnScreen from './functions/IntersectionObeserver';
import Backgrounds from "./components/Backgrounds";

// const PDF_FILE_URL = "http://localhost:3000/AguinaldoIanBenedict_CV.pdf";
const PDF_FILE_URL = "https://ianaguinaldo.me/AguinaldoIanBenedict_CV.pdf";
const KUGORUN_APK_URL = "https://ianaguinaldo.me/KugoRunner.apk";
const LETCARE_APK_URL = "https://ianaguinaldo.me/LetCare.apk";
const IMGRECOG_FLUTTER_APK_URL = "https://ianaguinaldo.me/ImageRecognitionFlutter.apk";

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const handleDarkToggle = () => {
    setDarkMode(!darkMode);
    if (darkMode) {
     document.documentElement.classList.remove('dark'); 
    } else {
     document.documentElement.classList.add('dark');
    } 
   }

   const [containerRefHero, isVisibleHero] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold:0.9
   })

   useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    }

    document.addEventListener("contextmenu", handleContextMenu)

    return () =>{
      document.removeEventListener("contextmenu", handleContextMenu)
    }
   })

   const downloadFileAt = (url) => {
    const fileName = url.split("/").pop();
    const aTag = document.createElement("a");
    aTag.href=url;
    aTag.setAttribute("download", fileName);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
   };

  return (
    <div id="home" className=" bg-slate-300 dark:bg-primary transition-all duration-500">
      <Navbar setDarkMode={handleDarkToggle} darkMode={darkMode}/>
      <Backgrounds setDarkMode={handleDarkToggle} darkMode={darkMode} />
      <div ref={containerRefHero}>
        {isVisibleHero?<Fade bottom distance="20%" duration={1500} opposite when={isVisibleHero}>
          <Hero darkMode={darkMode}/>
        </Fade> : <Fade top distance="20%" duration={1500} opposite when={isVisibleHero}>
          <Hero/>
        </Fade> }
      </div>
      <About downloadFunction={()=>{downloadFileAt(PDF_FILE_URL)}} darkMode={darkMode} />
      <Projects downloadKugoRunner={()=>{downloadFileAt(KUGORUN_APK_URL)}} downloadLetCare={()=>{downloadFileAt(LETCARE_APK_URL)}} downloadImgRecogFlutter={()=>{downloadFileAt(IMGRECOG_FLUTTER_APK_URL)}} />
      <Contacts />
      {/* </Fade> */}
      
      <SideMenu />
      <Fade top distance="10%" duration={1500}>
        <Footer />
      </Fade>
      
    </div>
  );
}

export default App;
