import { useEffect, useRef, useState } from "react"

const useElementOnScreen = (options) => {
 const containerRef = useRef(null)
 const [isVisible, setVisible] = useState(false);

 const callback = (entries) => {
  const [entry] = entries
  setVisible(entry.isIntersecting)
 }

 useEffect(() => {
  const observer = new IntersectionObserver(callback, options)
  if (containerRef.current) observer.observe(containerRef.current)

  return () => {
   if (containerRef.current) observer.unobserve(containerRef.current)
  }
 }, [containerRef, options])

 return [containerRef, isVisible]
}

export default useElementOnScreen;