import React,{ useRef } from "react";
import {AiOutlineClose, AiOutlineMenu, AiFillHome} from "react-icons/ai";
import {FaUserAlt} from 'react-icons/fa';
import {GrMail} from 'react-icons/gr';
import {IoIosFolder} from 'react-icons/io';

const SideMenu = () => {

 const handleHomeScroll = () => {
    const home = document.getElementById('home');
    if (home) {
      // 👇 Will scroll smoothly to the top of the next section
      home.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleAboutScroll = () => {
    const about = document.getElementById('about');
    if (about) {
      about.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleContactsScroll = () => {
    const contacts = document.getElementById('contacts');
    if (contacts) {
      contacts.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleProjectsScroll = () => {
    const projects = document.getElementById('projects');
    if (projects) {
      projects.scrollIntoView({ behavior: 'smooth' });
    }
  }

 return (
  <div className="flex justify-center lg:mx-auto lg:justify-end lg:max-w-[1200px]">
    <div className="lg:fixed lg:bottom-[35vh] fixed bottom-[10vh] text-blue-500 ">
      <div className=" lg:flex-col lg:justify-around lg:h-[300px] lg:w-[52px] flex justify-around w-[300px] bg-primary p-3 border-4 border-accent rounded-full">
        <button onClick={handleHomeScroll}>
          <AiFillHome className="hover:scale-150 duration-500 hover:text-accent text-gray-300" size={20}/>
        </button>
        <button onClick={handleAboutScroll}>
            <FaUserAlt className="hover:scale-150 duration-500 hover:text-accent text-gray-300" size={20}/>
        </button>
        <button onClick={handleProjectsScroll}>
          <IoIosFolder className="hover:scale-150 duration-500 hover:text-accent text-gray-300" size={20}/>
        </button>
        <button onClick={handleContactsScroll}>
          <GrMail className="hover:scale-150 duration-500 hover:text-accent text-gray-300" size={20}/>
        </button>
      </div>
    </div>
  </div>
 );
}


export default SideMenu;