import React, { useState, useRef } from "react";
import Lottie, {LottieRefCurrentProps} from "lottie-react";
import suncloudData from '../assets/animations/suncloudTog.json';
import mooncloudData from '../assets/animations/mooncloudTog.json';

const Navbar = ({ darkMode, setDarkMode }) => {
 const [hov, setHov] = useState(false);
 
 const toggleRef = useRef()

 const handleHov = () => {
  setHov(!hov);
 } 

 return (
   <div className=" sticky top-0 z-50 flex justify-between items-center px-4 h-24 mx-auto max-w-[1080px] text-primary dark:text-white">
    <div className="w-[90%] flex justify-between items-center">
     <div className="w-[10%]" onClick={setDarkMode}>
      {
        darkMode ?
          <Lottie className=" lg:w-[64px] md:w-[54px] w-[45px] mt-6 ml-6"
          onComplete={() => {
          }}
          lottieRef={toggleRef}
          animationData={mooncloudData} />
        
       : 
        <Lottie className=" lg:w-[64px] md:w-[54px] w-[45px] mt-6 ml-6"
        onComplete={() => {
        }}
        lottieRef={toggleRef}
        animationData={suncloudData} />
       
      }
      {/* <Lottie onComplete={() => {
      }}
      size={10}
      lottieRef={toggleRef}
      animationData={animationData} /> */}
     </div>
     <div>
       {/* <div className="relative z-0">
         {hov ? <h1 class="rotate-12 translate-y-6 duration-500 text-gray-600 font-semibold">IAN AGUINALDO</h1> : <h1 className="font-semibold">DEV PORTFOLIO</h1>}
         <div onMouseEnter={handleHov} onMouseLeave={handleHov} className="absolute inset-0 z-10 font-semibold">
           <h1 className="fixed"><a href='#home'>DEV PORTFOLIO</a></h1>
         </div>
       </div> */}
     </div>
     <div>
      {/* <div className=" transition-all duration-500 bg-slate-300 dark:bg-primary right-10 md:right-44 fixed z-0">
        {hov ? <h1 class=" -rotate-12 translate-y-6 duration-500 text-gray-600 font-semibold">IAN AGUINALDO</h1> : <h1 className="font-semibold">DEV PORTFOLIO</h1>}
        <div onMouseEnter={handleHov} onMouseLeave={handleHov} className="absolute inset-0 z-10 font-semibold">
          <h1 className=" drop-shadow-[0_3px_3px_rgba(0,0,0,0.9)] text-white dark:text-accent fixed"><a href='#home'>DEV PORTFOLIO</a></h1>
        </div>
      </div> */}
    </div>
    </div>
   </div>
 );
}

export default Navbar;