import React from "react";
import Logo from "../assets/portfolio-logo.png";
import Typed from 'react-typed';

const Hero = ({ darkMode }) => {


 return (
  <div className=" dark:text-white text-slate-900">
   <div className=" h-[calc(100vh-25vh)] md:w-[95vh] md:h-screen md:mt-[-96px] my-6 max-w-[800px] w-full mx-auto text-center flex flex-col justify-center ">
    <img className=" drop-shadow-lg md:w-[250px] w-[200px] hover:scale-110 duration-700 mx-auto" src={Logo} alt="" />
    {/* <h1 className="md:text-3xl text-xl font-bold md:py-3">Software Developer</h1> */}
    {darkMode ?
     <div className=" grid-cols-12 grid">
      <div className=" col-span-7 col-start-4">
       <div className=" grid-cols-9 grid">
        <div className=" pl-16 md:pl-32 col-start-2 col-span-2 flex justify-end">
         <Typed
          className="  md:text-2xl text-accent text-l px-2 pt-3"
          strings={['Sorcerer', 'Developer']}
          typeSpeed={90}
          backSpeed={90}
          showCursor={false}
          loop />
        </div>
        <div className=" pt-3 pr-[3px] items-center justify-end flex">
         <span className=" col-start-4 dark:text-white text-black">•</span>
        </div>
        <div className=" col-start-5 col-span-5  justify-start flex">
         <Typed
          className="  md:text-2xl text-accent text-l px-2 pt-3"
          strings={['Designer', 'Dragon Slayer']}
          typeSpeed={90}
          backSpeed={90}
          showCursor={false}
          loop />
        </div>
       </div>
      </div>
     </div> :
     <p className=" md:text-2xl text-accent text-l px-2 pt-3">Developer <span className="dark:text-white text-black">•</span> Designer</p>
    }
    {/* <p className=" md:text-2xl text-accent text-l px-2 pt-3">Developer <span className="dark:text-white text-black">•</span> Designer</p> */}
    <div className="flex justify-center items-center">
     <p className="md:text-2xl sm:text-xl text-lg font-semibold py-1">
      Creating Modern
     </p>
     <Typed
      className="md:pl-2 sm:pl-2 pl-1 md:text-2xl sm:text-xl text-xl font-semibold"
      strings={['Web App', 'Mobile App', 'Websites', 'Designs',]}
      typeSpeed={120}
      backSpeed={90}
      loop />
    </div>
    <p className="md:text-md md:px-2 font-bold text-sm text-gray-500 px-6">Solutions with creative and minimalist designs</p>
    <p className=" transition-all duration-500 dark:text-gray-500 px-6 text-slate-300 text-xs md:px-2 md:text-sm mt-6">Unifying magic and logic. Casting a spell to make the best out of it</p>
   </div>
  </div>
 );
}

export default Hero;