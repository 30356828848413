import React, { useRef } from "react";
// import {AiFillGithub, AiFillFacebook, AiFillLinkedin} from "react-icons/ai";
import {AiFillGithub, AiFillFacebook, AiFillLinkedin} from "react-icons/ai";
import {SiGmail} from "react-icons/si";
import emailjs from '@emailjs/browser';
import useElementOnScreen from "../functions/IntersectionObeserver";
import Fade  from "react-reveal/Fade"

const Contacts = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ewgcwar','template_e96t8qk',form.current,'3CHiTbsWPfHZcjlsH')
    .then((result) => {
      console.log(result.text);
    }, (error) => {
      console.log(error.text);
    }
    );
    e.target.reset();
  };

  const [containerRefContacts, isVisibleContacts] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold:0.9
  })

  const [containerRefIcons, isVisibleIcons] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold:0.9
  })

  const [containerRefSubtitle, isVisibleSubtitle] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold:0.9
  })

  const [containerRefForm, isVisibleForm] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold:0.8
  })

 return (
 <div id="contacts" className=" px-0 md:px-6 pt-32 py-14 flex flex-col justify-center">
      <div className="md:max-w-[1080px] md:flex flex-col md:items-start mx-auto w-full">
        <div className="md:flex md:items-center justify-between w-full">
            <h2 ref={containerRefContacts} className="dark:text-white mb-4 text-2xl tracking-tight font-bold text-center text-slate-900">
              {
                isVisibleContacts ? <Fade bottom duration={1000} opposite when={isVisibleContacts} >
                  <div>Contacts</div>
                  </Fade> : 
                  <Fade top duration={1000} opposite when={isVisibleContacts} >
                    <div>Cotacts</div>
                  </Fade>
              }
            </h2>

            <div ref={containerRefIcons} className="mb-4 flex gap-5 justify-center">
              {
                isVisibleIcons ? <Fade bottom duration={1000} opposite when={isVisibleIcons}>
                    <div className="mb-4 flex gap-5 justify-center">
                      <a href="https://github.com/ue-an" target="_blank" rel="noopener noreferral">
                        <AiFillGithub className="dark:text-white hover:scale-150 duration-500 dark:hover:text-accent hover:text-accent text-black" size={30}/>
                      </a>
                      <a href="https://www.facebook.com/profile.php?id=100093462218314" target="_blank" rel="noopener noreferral">
                        <AiFillFacebook className="dark:text-white hover:scale-150 duration-500 dark:hover:text-accent hover:text-accent text-black" size={30}/>
                      </a>
                      <a href="https://www.linkedin.com/in/ian-benedict-aguinaldo-a7524b282" target="_blank" rel="noopener noreferrer">
                        <AiFillLinkedin className="dark:text-white hover:scale-150 duration-500 dark:hover:text-accent hover:text-accent text-black" size={30}/>
                      </a>
                      <a className="dark:text-white text-black" href="mailto:ianbenedictaguinaldo@gmail.com">
                        <SiGmail className="dark:text-white hover:scale-150 duration-500 dark:hover:text-accent hover:text-accent text-black" size={30} />
                      </a>
                    </div>
                  </Fade> : 
                  <Fade top duration={1000} opposite when={isVisibleIcons}>
                  <div className="mb-4 flex gap-5 justify-center">
                    <AiFillGithub className="dark:text-white hover:scale-150 duration-500 hover:text-accent text-black" size={30}/>
                    <AiFillFacebook className="dark:text-white hover:scale-150 duration-500 hover:text-accent text-black" size={30}/>
                    <AiFillLinkedin className="dark:text-white hover:scale-150 duration-500 hover:text-accent text-black" size={30}/>
                    <a className="dark:text-white text-black" href="mailto:ianbenedictaguinaldo@gmail.com">
                      <SiGmail className="dark:text-white hover:scale-150 duration-500 hover:text-accent text-black" size={30} />
                    </a>
                  </div>
                </Fade>
              }
            </div>
        </div>
        {/* <p className="mb-8 lg:mb-16 font-light md:text-left text-center text-gray-500 sm:text-xl">Got a project? Want to send feedback? Need details and want to inquire? Let me know.</p> */}
        <div  ref={containerRefSubtitle}>
          {
            isVisibleSubtitle ? <Fade bottom duration={1000} opposite when={isVisibleSubtitle}>
              <div>
                <p className=" flex justify-center mb-8 lg:mb-16 font-light md:px-0 md:text-left text-center text-gray-500 sm:text-xl px-7">Got a project? Want to send feedback? Need details or quick inquiry? Let me know.</p>
              </div>
            </Fade> : 
            <Fade top duration={1000} opposite when={isVisibleSubtitle}>
              <div>
                <p className="mb-8 lg:mb-16 font-light md:px-0 md:text-left text-center text-gray-500 sm:text-xl px-7">Got a project? Want to send feedback? Need details or quick inquiry? Let me know.</p>
              </div>
            </Fade>
          }
        </div>
        
        <div ref={containerRefForm} className="md:px-0 w-full px-12">
          {
            isVisibleForm ? <Fade bottom duration={1000} opposite when={isVisibleForm} distance="20%">
            <div>
              <form 
                ref={form}
                onSubmit={sendEmail}
                className="space-y-8">
                  <div>
                      <label htmlFor="email" className="block mb-2 text-sm font-medium text-accent">Your email</label>
                      <input name="user_email" type="email" id="email" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="email@sample.com" required />
                  </div>
                  <div>
                      <label htmlFor="subject" className="block mb-2 text-sm font-medium text-accent">Subject</label>
                      <input name="subject" type="text" id="subject" className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="Let me know how I can help you" required />
                  </div>
                  <div className="sm:col-span-2">
                      <label htmlFor="message" className="block mb-2 text-sm font-medium text-accent">Your message</label>
                      <textarea name="message" id="message" rows="6" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Leave a comment..."></textarea>
                  </div>
                  <button type="submit" className="dark:text-black text-white py-3 px-5 text-sm font-semibold text-center rounded-lg bg-accent hover:opacity-50 duration-100">Send Message</button>
                  {/* <a className="text-white" href="mailto:ianbenedictaguinaldo@gmail.com">Send Message</a> */}
              </form>
            </div>
          </Fade> : 
          <Fade top duration={1000} opposite when={isVisibleForm} distance="20%">
            <div>
              <form 
                ref={form}
                onSubmit={sendEmail}
                className="space-y-8">
                  <div>
                      <label htmlFor="email" className="block mb-2 text-sm font-medium text-accent">Your email</label>
                      <input name="user_email" type="email" id="email" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="email@sample.com" required />
                  </div>
                  <div>
                      <label htmlFor="subject" className="block mb-2 text-sm font-medium text-accent">Subject</label>
                      <input name="subject" type="text" id="subject" className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="Let me know how I can help you" required />
                  </div>
                  <div className="sm:col-span-2">
                      <label htmlFor="message" className="block mb-2 text-sm font-medium text-accent">Your message</label>
                      <textarea name="message" id="message" rows="6" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Leave a comment..."></textarea>
                  </div>
                  <button type="submit" className="dark:text-black text-white py-3 px-5 text-sm font-semibold text-center rounded-lg bg-accent hover:opacity-50 duration-100">Send Message</button>
                  {/* <a className="text-white" href="mailto:ianbenedictaguinaldo@gmail.com">Send Message</a> */}
              </form>
            </div>
          </Fade>
          }
        </div>
      </div>
      
  </div>
 );
}

export default Contacts;