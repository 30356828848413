import React from "react";
import ProfileIMG from '../assets/profile-img2-nobg.png';
import { SiJavascript, SiLaravel, SiPhp, SiFlutter, SiReact, SiTailwindcss } from 'react-icons/si';
import useElementOnScreen from "../functions/IntersectionObeserver";
import { Fade } from "react-reveal";

const About = ({ downloadFunction, darkMode }) => {

  const [contanierRefProfile, isVisibleProfile] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.8
  })

  const [containerRefHello, isVisibleHello] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.9
  })

  const [containerRefDescription, isVisibleDescription] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.99
  })

  const [containerRefIcons, isVisibleIcons] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.9
  })

  const [containerRefIcons2, isVisibleIcons2] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.9
  })

  const [containerRefRow, isVisibleRow] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.9
  })

  const [containerRefRow2, isVisibleRow2] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.9
  })

  const [containerRefRow3, isVisibleRow3] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.9
  })

  const [containerRefRow4, isVisibleRow4] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.9
  });


  return (
    <div id="about" className="md:mt-0 pt-14 pb-8 px-8 w-full scroll-mt-10 flex items-center">
      <div className="flex-col items-center justify-center m-auto">
        <div className=" dark:text-white font-bold text-2xl">About</div>
        <div className="max-w-[1080px] mx-auto grid md:grid-cols-2 grid-cols-1 ">
          <div ref={contanierRefProfile} className=" hover:scale-105 duration-500">
            {isVisibleProfile ? <Fade bottom duration={2000} opposite when={isVisibleProfile} distance="10%">
              <div className=" mb-6 grid-cols-1 grid">
                <img className=" drop-shadow-lg rounded-full hover:scale-105 duration-500 md:w-[230px] w-[200px] mx-auto my-4" src={ProfileIMG} alt="" />
                <button onClick={downloadFunction} className="py-2 px-2 md:mx-32 mx-20 drop-shadow-lg transition-all duration-300 hover:-translate-y-2 hover:bg-cyan-600 bg-gray-700 text-gray-100 font-semibold text-sm md:text-lg rounded-full">Download CV</button>
              </div>
            </Fade> : <Fade top duration={2000} opposite when={isVisibleProfile} distance="10%">
              <div className=" mb-6 grid-cols-1 grid">
                <img className=" drop-shadow-lg rounded-full hover:scale-105 duration-500 md:w-[230px] w-[200px] mx-auto my-4" src={ProfileIMG} alt="" />
                <button onClick={downloadFunction} className=" py-2 px-2 md:mx-32 mx-20 drop-shadow-lg transition-all duration-300 hover:-translate-y-2 hover:bg-cyan-600 bg-gray-700 text-gray-100 font-semibold text-sm md:text-lg rounded-full">Download CV</button>
              </div>
            </Fade>
            }
          </div>

          <div className="flex flex-col justify-center items-center md:items-start">
            <div className="w-full" ref={containerRefHello}>
              {isVisibleHello ? <Fade bottom duration={1500} opposite when={isVisibleHello}>
                <p className=" dark:text-gray-300 text-slate-800 font-bold">Hello, I am</p>
                <h1 className=" drop-shadow-lg md:text-4xl sm:text-3xl text-2xl text-primary dark:text-accent font-bold py-2">Ian Benedict Aguinaldo</h1>
              </Fade> : <Fade top duration={1500} opposite when={isVisibleHello}>
                <p className=" dark:text-white text-slate-800 font-bold">Hello, I am</p>
                <h1 className="md:text-4xl sm:text-3xl text-2xl text-slate-900 dark:text-accent font-bold py-2">Ian Benedict Aguinaldo</h1>
              </Fade>
              }
            </div>

            <div ref={containerRefDescription}>
              {isVisibleDescription ? <Fade bottom duration={1500} opposite when={isVisibleDescription}>
                <div>
                  <p className=" dark:text-gray-400 text-gray-900 text-justify text-sm">A full stack web developer. Typically using RectJS, Tailwind CSS styling for frontend, PHP for backend, and MySQL for database.</p>
                  <p className=" mt-3 dark:text-gray-400 text-gray-900 text-justify text-sm">Currently learning REST and API integrations along with other frameworks and tech stacks. Open for opportunities to be trained if the task is not within my scope but within mutual benefit for me and the company.</p>
                </div>
              </Fade> :
                <Fade top duration={1500} opposite when={isVisibleDescription}>
                  <div>
                    <p className=" dark:text-gray-400 text-gray-900 text-justify text-sm">A full stack web developer. Typically using RectJS, Tailwind CSS styling for frontend, PHP for backend, and MySQL for database.</p>
                    <p className=" mt-3 dark:text-gray-400 text-gray-900 text-justify text-sm">Currently learning REST and API integrations along with other frameworks and tech stacks. Open for opportunities to be trained if the task is not within my scope but within mutual benefit for me and the company.</p>
                  </div>
                </Fade>
              }
            </div>

            <div ref={containerRefIcons} className="mb-9 w-full mt-3 flex gap-3 justify-around duration-500">
              {isVisibleIcons ?
                <Fade bottom duration={1500} opposite when={isVisibleIcons}>
                  <SiTailwindcss className=" drop-shadow-lg hover:scale-150 duration-500 dark:hover:text-accent dark:text-gray-300 text-slate-800 hover:text-accent" size={45} />
                  <SiJavascript className=" drop-shadow-lg hover:scale-150 duration-500 dark:hover:text-accent dark:text-gray-300 text-slate-800 hover:text-accent" size={45} />
                  <SiPhp className=" drop-shadow-lg hover:scale-150 duration-500 dark:hover:text-accent dark:text-gray-300 text-slate-800 hover:text-accent" size={45} />
                </Fade> :
                <Fade top duration={1500} opposite when={isVisibleIcons}>
                  <SiTailwindcss className="hover:scale-150 duration-500 dark:hover:text-accent dark:text-gray-300 text-slate-800 hover:text-accent" size={45} />
                  <SiJavascript className="hover:scale-150 duration-500 dark:hover:text-accent dark:text-gray-300 text-slate-800 hover:text-accent" size={45} />
                  <SiPhp className="hover:scale-150 duration-500 dark:hover:text-accent dark:text-gray-300 text-slate-800 hover:text-accent" size={45} />
                </Fade>
              }
            </div>

            <div ref={containerRefIcons2} className="w-full mt-3 flex gap-3 justify-around duration-500">
              {isVisibleIcons2 ?
                <Fade bottom duration={1500} opposite when={isVisibleIcons2}>
                  <SiFlutter className=" drop-shadow-lg hover:scale-150 duration-500 dark:hover:text-accent dark:text-gray-300 text-slate-800 hover:text-accent" size={45} />
                  <SiReact className=" drop-shadow-lg hover:scale-150 duration-500 dark:hover:text-accent dark:text-gray-300 text-slate-800 hover:text-accent" size={45} />
                  <SiLaravel className=" drop-shadow-lg hover:scale-150 duration-500 dark:hover:text-accent dark:text-gray-300 text-slate-800 hover:text-accent" size={45} />
                </Fade> :
                <Fade top duration={1500} opposite when={isVisibleIcons2}>
                  <SiFlutter className="hover:scale-150 duration-500 dark:hover:text-accent dark:text-gray-300 text-slate-800 hover:text-accent" size={45} />
                  <SiReact className="hover:scale-150 duration-500 dark:hover:text-accent dark:text-gray-300 text-slate-800 hover:text-accent" size={45} />
                  <SiLaravel className="hover:scale-150 duration-500 dark:hover:text-accent dark:text-gray-300 text-slate-800 hover:text-accent" size={45} />
                </Fade>}
            </div>
          </div>
        </div>

        <div className=" space-y-6 pt-6 max-w-[1000px] m-auto w-full grid grid-cols-12">
          {/* <div className=" m-auto col-start-1 grid">
        <div className=" rounded-full h-[150px] border-2 border-solid border-primary dark:border-accent"></div>
      </div> */}
          <div ref={containerRefRow} className=" dark:text-gray-400 text-slate-800 text-justify col-span-10 md:col-span-12 col-start-2">
            {
              isVisibleRow ?
                <Fade bottom duration={1500} opposite when={isVisibleRow}>
                  <div className={!darkMode ? " text-slate-600 flex flex-col justify-center" : " text-slate-400 flex flex-col justify-center"}>
                    <div className=" pb-5 font-bold dark:text-gray-300 text-xl">Background</div>
                    <div className=" font-bold ">{darkMode ? "Angono National High School (2017 - 2019)" : "Angono National High School (2017 - 2019)"}</div>
                    {
                      !darkMode ?
                        // <div>
                        //   <div>• Took ICT / Technical-Vocational Strand</div>
                        //   <div>• Learned Fundamentals and Basics of Programming</div>
                        //   <div>• Java Programming</div>
                        //   <div>• TESDA NC II: Computer Systems Servicing Certification</div>
                        //   <div>• DOST RA 7687 Scholarship</div>
                        //   <div>• Graduated With Honors</div>
                        // </div> :
                        <div className=" mt-3">
                          My journey as a developer started when I was in senior high school by taking ICT strand; giving me knowledge about both hardware and software. I learned the fundamentals of programming that time and got interested. The first language I encounter was Java. Then I continued to pursue programming by taking Bachelor of Science in Information Technology in college.
                        </div> :
                        <div className=" mt-3">
                          My journey as a developer started when I was in senior high school by taking ICT strand; giving me knowledge about both hardware and software. I learned the fundamentals of programming that time and got interested. The first language I encounter was Java. Then I continued to pursue programming by taking Bachelor of Science in Information Technology in college.
                        </div>
                    }
                  </div>
                </Fade> :
                <Fade top duration={1500} opposite when={isVisibleRow}>
                  <div className={!darkMode ? " text-slate-600 flex flex-col justify-center" : " text-slate-400 flex flex-col justify-center"}>
                    <div className=" font-bold ">{darkMode ? "Angono National High School (2017 - 2019)" : "Angono National High School (2017 - 2019)"}</div>
                    {
                      !darkMode ?
                        // <div>
                        //   <div>• Took ICT / Technical-Vocational Strand</div>
                        //   <div>• Learned Fundamentals and Basics of Programming</div>
                        //   <div>• Java Programming</div>
                        //   <div>• TESDA NC II: Computer Systems Servicing Certification</div>
                        //   <div>• DOST RA 7687 Scholarship</div>
                        //   <div>• Graduated With Honors</div>
                        // </div> :
                        <div className=" mt-3">
                          My journey as a developer started when I was in senior high school by taking ICT strand; giving me knowledge about both hardware and software. I learned the fundamentals of programming that time and got interested. The first language I encounter was Java. Then I continued to pursue programming by taking Bachelor of Science in Information Technology in college.
                        </div> :
                        <div className=" mt-3">
                          My journey as a developer started when I was in senior high school by taking ICT strand; giving me knowledge about both hardware and software. I learned the fundamentals of programming that time and got interested. The first language I encounter was Java. Then I continued to pursue programming by taking Bachelor of Science in Information Technology in college.
                        </div>
                    }
                  </div>
                </Fade>
            }
          </div>

          <div ref={containerRefRow2} className=" dark:text-gray-400 text-slate-800 text-justify col-span-10 md:col-span-12 col-start-2">
            {
              isVisibleRow2 ?
                <Fade bottom duration={1500} opposite when={isVisibleRow2}>
                  <div className={!darkMode ? " text-slate-600 flex flex-col justify-center" : " text-slate-400 flex flex-col justify-center"}>
                    <div className=" font-bold ">{darkMode ? "University of Rizal System Binangonan (2019 - 2023)" : "University of Rizal System Binangonan (2019 - 2023)"}</div>
                    {
                      !darkMode ?
                        // <div>
                        //   <div>• Bachelor of Science in Information Technology (Graduated 2023)</div>
                        //   <div>• C# Programming, Desktop Application Development, Web Development, Game Development, and Mobie Application Development</div>
                        //   <div>• Lead developer and project manager in capstone project</div>
                        //   <div>• Competed in Student Research Congress</div>
                        //   <div>• 2nd Place in CCS C# Programming Competition</div>
                        //   <div>• Full Stack Freelance (Briefly)</div>
                        //   <div>• Completed courses:</div>
                        //   <div>‎‎‎‎‎‎‎‎ㅤCoursera: Foundations of User Experience (UX) Design</div>
                        //   <div>‎‎‎‎‎‎‎‎ㅤDICT: Laravel PHP Framework Course</div>
                        //   <div>• Shepherd's Voice Radio and Television Foundation, Inc. Internship (Web Team Developer Intern)</div>
                        // </div> :
                        <div className=" mt-3">
                          Throughout college, I have acquired Laravel certification, winning 2nd Place in C# Programming Competition in our campus, and UX/UI Design Certification from Coursera. I have also been a lead developer and project manager in our capstone project, a founding member of a programming organization in our college, having a collaboration with other programmers, having experience in freelancing for more or less than a year and participating in 16th Student Research Congress. Learned Visual Basic, C# and other programming languages, extending to newer technologies and some frameworks I am using up until now.
                        </div> :
                        <div className=" mt-3">
                          Throughout college, I have acquired Laravel certification, winning 2nd Place in C# Programming Competition in our campus, and UX/UI Design Certification from Coursera. I have also been a lead developer and project manager in our capstone project, a founding member of a programming organization in our college, having a collaboration with other programmers, having experience in freelancing for more or less than a year and participating in 16th Student Research Congress. Learned Visual Basic, C# and other programming languages, extending to newer technologies and some frameworks I am using up until now.
                        </div>
                    }
                  </div>
                </Fade> :
                <Fade top duration={1500} opposite when={isVisibleRow2}>
                  <div className={!darkMode ? " text-slate-600 flex flex-col justify-center" : " text-slate-400 flex flex-col justify-center"}>
                    <div className=" font-bold ">{darkMode ? "" : "University of Rizal System Binangonan (2019 - 2023)"}</div>
                    {
                      !darkMode ?
                        // <div>
                        //   <div>• Bachelor of Science in Information Technology (Graduated 2023)</div>
                        //   <div>• C# Programming, Desktop Application Development, Web Development, Game Development, and Mobie Application Development</div>
                        //   <div>• Lead developer and project manager in capstone project</div>
                        //   <div>• Competed in Student Research Congress</div>
                        //   <div>• 2nd Place in CCS C# Programming Competition</div>
                        //   <div>• Full Stack Freelance (Briefly)</div>
                        //   <div>• Completed courses:</div>
                        //   <div>‎‎‎‎‎‎‎‎ㅤCoursera: Foundations of User Experience (UX) Design</div>
                        //   <div>‎‎‎‎‎‎‎‎ㅤDICT: Laravel PHP Framework Course</div>
                        //   <div>• Shepherd's Voice Radio and Television Foundation, Inc. Internship (Web Team Developer Intern)</div>
                        // </div> :
                        <div className=" mt-3">
                          Throughout college, I have acquired Laravel certification, winning 2nd Place in C# Programming Competition in our campus, and UX/UI Design Certification from Coursera. I have also been a lead developer and project manager in our capstone project, a founding member of a programming organization in our college, having a collaboration with other programmers, having experience in freelancing for more or less than a year and participating in 16th Student Research Congress. Learned Visual Basic, C# and other programming languages, extending to newer technologies and some frameworks I am using up until now.
                        </div> :
                        <div className=" mt-3">
                          Throughout college, I have acquired Laravel certification, winning 2nd Place in C# Programming Competition in our campus, and UX/UI Design Certification from Coursera. I have also been a lead developer and project manager in our capstone project, a founding member of a programming organization in our college, having a collaboration with other programmers, having experience in freelancing for more or less than a year and participating in 16th Student Research Congress. Learned Visual Basic, C# and other programming languages, extending to newer technologies and some frameworks I am using up until now.
                        </div>
                    }
                  </div>
                </Fade>
            }
          </div>

          <div ref={containerRefRow3} className=" dark:text-slate-400 text-slate-800 text-justify col-span-10 md:col-span-12 col-start-2">
            {
              isVisibleRow3 ?
                <Fade bottom duration={1500} opposite when={isVisibleRow3}>
                  {/* On my preference, I want to focus more on front-end development as of the moment despite having created full-stack projects already. Thinking about my career, I am dedicating myself to do not just innovative and helpful solutions in the industry, but also as creative as it can be. I thought, if we could make something amazing like what we are doing, maybe we could spice things up a bit by casting a little magic in it. I would like to have more fun in the upcoming years as a developer while expolring new heights and depth in technology. */}
                  <div className="flex flex-col justify-center">
                    {
                      !darkMode ?
                        <div>
                          I am determined to do not just innovative and helpful solutions in the industry, but also as creative as it can be. I thought, if we could make something amazing like what we have been doing, maybe we could spice things up a bit by casting a little magic in it. It would be great to have more fun in the upcoming years as a developer while exploring new heights and depth in technology. Looking forward to working with you.
                        </div> :
                        <div>
                          I am determined to do not just innovative and helpful solutions in the industry, but also as creative as it can be. I thought, if we could make something amazing like what we have been doing, maybe we could spice things up a bit by casting a little magic in it. It would be great to have more fun in the upcoming years as a developer while exploring new heights and depth in technology. Looking forward to working with you.
                        </div>
                    }
                  </div>
                </Fade> :
                <Fade top duration={1500} opposite when={isVisibleRow3}>
                  {
                    !darkMode ?
                      <div>
                        I am determined to do not just innovative and helpful solutions in the industry, but also as creative as it can be. I thought, if we could make something amazing like what we have been doing, maybe we could spice things up a bit by casting a little magic in it. It would be great to have more fun in the upcoming years as a developer while exploring new heights and depth in technology. Looking forward to working with you.
                      </div> :
                      <div>
                        I am determined to do not just innovative and helpful solutions in the industry, but also as creative as it can be. I thought, if we could make something amazing like what we have been doing, maybe we could spice things up a bit by casting a little magic in it. It would be great to have more fun in the upcoming years as a developer while exploring new heights and depth in technology. Looking forward to working with you.
                      </div>
                  }
                </Fade>
            }
          </div>

          {/* <div ref={containerRefRow4} className=" dark:text-gray-400 text-slate-800 text-justify col-span-10 md:col-span-12 col-start-2">
            {
              isVisibleRow4 ?
                <Fade bottom duration={1500} opposite when={isVisibleRow4}>
                  <div className=" pb-5 font-bold dark:text-gray-300 text-xl">What I can provide</div>
                  <div className=" font-bold text-gray-700">{darkMode ? "" : "Technical Skills"}</div>
                  <div className={!darkMode ? " text-slate-600 flex flex-col justify-center" : " text-slate-400 flex flex-col justify-center"}>
                    {
                      !darkMode ?
                        <div>
                          <div>• Full Stack Web Development</div>
                          <div>• Frontend Development</div>
                          <div>• Backend Development</div>
                          <div>• Web/Frontend Design</div>
                        </div> :
                        <div>
                          <div>• Full Stack Web Development</div>
                          <div>• Frontend Development</div>
                          <div>• Backend Development</div>
                          <div>• Web/Frontend Design</div>
                        </div>
                    }
                  </div>
                </Fade> :
                <Fade top duration={1500} opposite when={isVisibleRow4}>
                  {
                    !darkMode ?
                      <div>
                        <div>• Took ICT / Technical-Vocational Strand</div>
                        <div>• Learned Fundamentals and Basics of Programming</div>
                        <div>• Java Programming</div>
                        <div>• TESDA NC II: Computer Systems Servicing Certification</div>
                        <div>• DOST RA 7687 Scholarship</div>
                        <div>• Graduated With Honors</div>
                      </div> :
                      "My journey as a developer started when I was in senior high school by taking ICT strand; giving me knowledge about both hardware and software. I learned the fundamentals of programming that time and got interested. The first language I encounter was Java. Then I continued to pursue programming by taking Bachelor of Science in Information Technology in college."
                  }
                </Fade>
            }
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default About