import React, { useRef, useState } from "react";
import Usapp from '../assets/projectcovers/UsAPP_cover.png';
import Letcare from '../assets/projectcovers/Letcare_cover.png';
import KugoRunner from '../assets/projectcovers/KugoRunner_cover.png';
import PortfolioV1 from '../assets/projectcovers/PortfolioV1_Cover.png';
import SVRTV from '../assets/projectcovers/svrtv_cover.png';
import YourAniList from '../assets/projectcovers/youranilist_cover.png';
import AniWorld from '../assets/projectcovers/AniWorld_cover.png';
import ReactTodo from '../assets/projectcovers/react-todoapp-cover.png';
import PostBook from '../assets/projectcovers/postbook-cover4.png';
import ImgRecognition from '../assets/projectcovers/img-recognition-flutter-cover.png';
import SVRTVdemo from '../assets/projectcovers/svrtv-demo-cover.png';
import MLTraining from '../assets/projectcovers/ml-role-training-coverr.png';
import ExpenseManager from '../assets/projectcovers/expense-manager_cover.png';
import { Fade } from 'react-reveal';
import useElementOnScreen from "../functions/IntersectionObeserver";
import ProjectButtons from "./ProjectButtons";

const Projects = ({ downloadKugoRunner, downloadLetCare, downloadImgRecogFlutter }) => {

  const [containerRefProjects, isVisibleProjects] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.9
  })

  const [containerRefCell1, isVisibleCell1] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.8
  })

  const [containerRefCell2, isVisibleCell2] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.8
  })

  const [containerRefCell3, isVisibleCell3] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.8
  })

  const [containerRefCell4, isVisibleCell4] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.8
  })

  const [containerRefCell5, isVisibleCell5] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.8
  })

  const [containerRefCell6, isVisibleCell6] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.8
  })

  const [containerRefCell7, isVisibleCell7] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.8
  })

  const [containerRefCell8, isVisibleCell8] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.8
  })

  const [containerRefCell9, isVisibleCell9] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.8
  })

  const [containerRefCell10, isVisibleCell10] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.8
  })

  const [containerRefCell11, isVisibleCell11] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.8
  })

  const [containerRefCell12, isVisibleCell12] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.8,
  })

  const [containerRefCell13, isVisibleCell13] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.8,
  })

  return (
    <div id="projects" className=" px-0 md:px-6 pt-28">
      <div className=" md:max-w-[1080px] flex justify-center m-auto">
        <div className="w-full flex flex-col justify-start md:text-4xl sm:text-3xl text-2xl tracking-tight max-w-[1080px]">
          <div ref={containerRefProjects} className=" text-2xl dark:text-white text-slate-900 font-bold md:m-0 m-auto">
            {
              isVisibleProjects ? <Fade bottom duration={1500} opposite when={isVisibleProjects}>
                <p>Featured Projects</p>
              </Fade> :
                <Fade top duration={1500} opposite when={isVisibleProjects}>
                  <p>Featured Projects</p>
                </Fade>
            }
          </div>


          <div className=" pt-9 grid lg:grid-cols-3 md:grid-cols-2 gap-y-12 gap-x-4">
            <div ref={containerRefCell1} className=" space-y-3 py-3 m-auto grid">
              {
                isVisibleCell1 ? <Fade bottom duration={1000} opposite when={isVisibleCell1} distance="10%">
                  <div className=" space-y-3 font-semibold text-sm text-slate-600">
                    <div>Portfolio v1</div>
                    <div className="gap-3 flex">
                      <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-cyan-500 text-gray-100 font-normal text-sm">React</div>
                      <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-blue-400 text-gray-100 font-normal text-sm">Tailwind</div>
                    </div>

                    {/* start of project cover */}
                    <div className="w-[350px] relative block group">
                      <img className=" m-auto absolute inset-0 group-hover:opacity-20 w-[350px]" src={PortfolioV1} alt="" />

                      <div className="relative">
                        {/* Hidden content */}
                        <div className="transition transform
                                  translate-y-8 opacity-0
                                  group-hover:opacity-100
                                  group-hover:translate-y-0
                                  duration-500"
                        >
                          <div className=" p-20">
                            <div className=" gap-1 flex-col flex pt-6">
                              <a rel="noopener noreferrer" target="_blank" href="https://ianaguinaldo-portfolio.web.app/">
                                <button className=" duration-200 transition-all py-1 px-2 hover:bg-cyan-600 rounded-full w-full bg-gray-700 text-gray-100 font-normal" >Demo</button>
                              </a>
                              <a rel="noopener noreferrer" target="_blank" href="https://github.com/ue-an/devportfolio">
                                <button className=" duration-200 transition-all py-1 px-2 hover:bg-cyan-600 rounded-full w-full bg-gray-700 text-gray-100 font-normal" >Code</button>
                              </a>
                            </div>
                          </div>
                        </div>
                        {/* End of hidden content */}
                      </div>
                    </div>
                    {/* end of project cover */}
                  </div>
                </Fade> :
                  <Fade top duration={1000} opposite when={isVisibleCell1} distance="10%">
                    <div className=" space-y-3 font-semibold text-sm text-slate-600">
                      <div>Portfolio v1</div>
                      <div className=" gap-3 flex">
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-cyan-500 text-gray-100 font-normal text-sm">React</div>
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-blue-400 text-gray-100 font-normal text-sm">Tailwind</div>
                      </div>

                      {/* start of project cover */}
                      <div className="w-[350px] relative block group">
                        <img className=" m-auto absolute inset-0 group-hover:opacity-20 w-[350px]" src={PortfolioV1} alt="" />

                        <div className="relative">
                          {/* Hidden content */}
                          <div className="transition transform
                                    translate-y-8 opacity-0
                                    group-hover:opacity-100
                                    group-hover:translate-y-0
                                    duration-500"
                          >
                            <div className=" p-20">
                              <div className=" gap-1 flex-col flex pt-6">
                                <a rel="noopener noreferrer" target="_blank" href="https://ianaguinaldo-portfolio.web.app/">
                                  <button className=" duration-200 transition-all py-1 px-2 hover:opacity-70 rounded-full w-full bg-gray-700 text-gray-100 font-normal" >Demo</button>
                                </a>
                                <a rel="noopener noreferrer" target="_blank" href="https://github.com/ue-an/devportfolio">
                                  <button className=" duration-200 transition-all py-1 px-2 hover:bg-cyan-600 rounded-full w-full bg-gray-700 text-gray-100 font-normal" >Code</button>
                                </a>
                              </div>
                            </div>
                          </div>
                          {/* End of hidden content */}
                        </div>
                      </div>
                      {/* end of project cover */}
                    </div>
                  </Fade>
              }
            </div>

            <div ref={containerRefCell2} className=" space-y-3 py-3 m-auto grid">
              {
                isVisibleCell2 ? <Fade bottom duration={1000} opposite when={isVisibleCell2} distance="10%">
                  <div className=" space-y-3 font-semibold text-sm text-slate-600">
                    <div>React To-Do App</div>
                    <div className=" gap-3 flex">
                      <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-cyan-500 text-gray-100 font-normal text-sm">React</div>
                      <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-blue-400 text-gray-100 font-normal text-sm">Tailwind</div>
                    </div>

                    {/* start of project cover */}
                    <div className=" w-[350px] relative block group">
                      <img className=" m-auto absolute inset-0 group-hover:opacity-20 w-[350px]" src={ReactTodo} alt="" />

                      <div className="relative">
                        {/* hidden content */}
                        <div className="transition transform
                                        translate-y-8 opacity-0
                                        group-hover:opacity-100
                                        group-hover:translate-y-0
                                        duration-500"
                        >
                          <div className=" p-20">
                            <div className=" gap-1 flex-col flex pt-6">
                              <a rel="noopener noreferrer" target="_blank" href="https://simple-todo-react-tailwind.netlify.app">
                                <button className=" duration-200 transition-all py-1 px-2 hover:bg-cyan-600 rounded-full w-full bg-gray-700 text-gray-100 font-normal" >Demo</button>
                              </a>
                              <a rel="noopener noreferrer" target="_blank" href="https://github.com/ue-an/react-tailwind-todoapp">
                                <button className=" duration-200 transition-all py-1 px-2 hover:bg-cyan-600 rounded-full w-full bg-gray-700 text-gray-100 font-normal" >Code</button>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fade> :
                  <Fade top duration={1000} opposite when={isVisibleCell2} distance="10%">
                    <div className=" space-y-3 font-semibold text-sm text-slate-600">
                      <div>React To-Do App</div>
                      <div className=" gap-3 flex">
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-cyan-500 text-gray-100 font-normal text-sm">React</div>
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-blue-400 text-gray-100 font-normal text-sm">Tailwind</div>
                      </div>

                      {/* start of project cover */}
                      <div className=" w-[350px] relative block group">
                        <img className=" m-auto absolute inset-0 group-hover:opacity-20 w-[350px]" src={ReactTodo} alt="" />

                        <div className="relative">
                          {/* hidden content */}
                          <div className="transition transform
                                      translate-y-8 opacity-0
                                      group-hover:opacity-100
                                      group-hover:translate-y-0
                                      duration-500"
                          >
                            <div className=" p-20">
                              <div className=" gap-1 flex-col flex pt-6">
                                <a rel="noopener noreferrer" target="_blank" href="https://simple-todo-react-tailwind.netlify.app">
                                  <button className=" duration-200 transition-all py-1 px-2 hover:bg-cyan-600 rounded-full w-full bg-gray-700 text-gray-100 font-normal" >Demo</button>
                                </a>
                                <a rel="noopener noreferrer" target="_blank" href="https://github.com/ue-an/react-tailwind-todoapp">
                                  <button className=" duration-200 transition-all py-1 px-2 hover:bg-cyan-600 rounded-full w-full bg-gray-700 text-gray-100 font-normal" >Code</button>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fade>
              }
            </div>

            <div ref={containerRefCell13} className=" space-y-3 py-3 m-auto grid">
              {
                isVisibleCell13 ? <Fade bottom duration={1000} opposite when={isVisibleCell13} distance="10%">
                  <div className=" space-y-3 font-semibold text-sm text-slate-600">
                    <div>Expense Manager: Expense Tracker Web Application</div>
                    <div className=" gap-3 flex">
                      <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-blue-900 text-gray-100 font-normal text-sm">PHP</div>
                      <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-red-500 text-gray-100 font-normal text-sm">Laravel</div>
                      <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-orange-500 text-gray-100 font-normal text-sm">MySQL</div>
                      <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-blue-400 text-gray-100 font-normal text-sm">Tailwind</div>
                      <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-emerald-800 text-gray-100 font-normal text-sm">Vue</div>
                    </div>

                    {/* start of project cover */}
                    <div className=" w-[350px] relative block group">
                      <img className=" m-auto absolute inset-0 w-[350px] group-hover:opacity-20" src={ExpenseManager} alt="" />

                      <div className="relative">
                        {/* hidden content */}
                        <div className="transition-all transform
                                    translate-y-8 opacity-0
                                    group-hover:opacity-100
                                    group-hover:translate-y-0
                                    duration-500
                                    ">
                          <div className=" p-20">
                            <div className=" gap-1 flex-col flex pt-4">
                              <a rel="noopener norefferer" target="_blank" href="https://expense-manager-portfolio-uean.online/">
                                <button className=" font-normal transition-all duration-200 hover:bg-cyan-600 text-gray-100 w-full m-auto bg-gray-700 py-1 px-2 rounded-full">Demo</button>
                              </a>
                              <a rel="noopener norefferer" target="_blank" href="https://github.com/ue-an/expense-manager-portfolio-uean">
                                <button className=" font-normal transition-all duration-200 hover:opacity-70 text-gray-100 w-full m-auto bg-gray-700 py-1 px-2 rounded-full">Code</button>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fade> :
                  <Fade bottom duration={1000} opposite when={isVisibleCell13} distance="10%">
                    <div className=" space-y-3 font-semibold text-sm text-slate-600">
                      <div>Expense Manager: Expense Tracker Web Application</div>
                      <div className=" gap-3 flex">
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-blue-900 text-gray-100 font-normal text-sm">PHP</div>
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-red-500 text-gray-100 font-normal text-sm">Laravel</div>
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-orange-500 text-gray-100 font-normal text-sm">MySQL</div>
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-blue-400 text-gray-100 font-normal text-sm">Tailwind</div>
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-emerald-800 text-gray-100 font-normal text-sm">Vue</div>
                      </div>

                      {/* start of project cover */}
                      <div className=" w-[350px] relative block group">
                        <img className=" m-auto absolute inset-0 w-[350px] group-hover:opacity-20" src={ExpenseManager} alt="" />

                        <div className="relative">
                          {/* hidden content */}
                          <div className="transition-all transform
                                    translate-y-8 opacity-0
                                    group-hover:opacity-100
                                    group-hover:translate-y-0
                                    duration-500
                                    ">
                            <div className=" p-20">
                              <div className=" gap-1 flex-col flex pt-4">
                                <a rel="noopener norefferer" target="_blank" href="https://expense-manager-portfolio-uean.online/">
                                  <button className=" font-normal transition-all duration-200 hover:bg-cyan-600 text-gray-100 w-full m-auto bg-gray-700 py-1 px-2 rounded-full">Demo</button>
                                </a>
                                <a rel="noopener norefferer" target="_blank" href="https://github.com/ue-an/expense-manager-portfolio-uean">
                                  <button className=" font-normal transition-all duration-200 hover:opacity-70 text-gray-100 w-full m-auto bg-gray-700 py-1 px-2 rounded-full">Code</button>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fade>
              }
            </div>

            <div ref={containerRefCell3} className=" space-y-3 py-3 m-auto grid">
              {
                isVisibleCell3 ? <Fade bottom duration={1000} opposite when={isVisibleCell3} distance="10%">
                  <div className=" space-y-3 font-semibold text-sm text-slate-600">
                    <div>PostBook: Poems & Quotes</div>
                    <div className=" gap-3 flex">
                      <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-cyan-500 text-gray-100 font-normal text-sm">React</div>
                      <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-blue-400 text-gray-100 font-normal text-sm">Tailwind</div>
                      <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-emerald-800 text-gray-100 font-normal text-sm">Rest API</div>
                    </div>

                    {/* start of project cover */}
                    <div className=" w-[350px] relative block group">
                      <img className=" m-auto absolute inset-0 w-[350px] group-hover:opacity-20" src={PostBook} alt="" />

                      <div className="relative">
                        {/* hidden content */}
                        <div className="transition-all transform
                                    translate-y-8 opacity-0
                                    group-hover:opacity-100
                                    group-hover:translate-y-0
                                    duration-500
                                    ">
                          <div className=" p-20">
                            <div className=" gap-1 flex-col flex pt-4">
                              <a rel="noopener norefferer" target="_blank" href="https://post-book-uean.netlify.app/">
                                <button className=" font-normal transition-all duration-200 hover:bg-cyan-600 text-gray-100 w-full m-auto bg-gray-700 py-1 px-2 rounded-full">Demo</button>
                              </a>
                              <a rel="noopener norefferer" target="_blank" href="https://github.com/ue-an/postbook-uean">
                                <button className=" font-normal transition-all duration-200 hover:opacity-70 text-gray-100 w-full m-auto bg-gray-700 py-1 px-2 rounded-full">Code</button>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fade> :
                  <Fade top duration={1000} opposite when={isVisibleCell3} distance="10%">
                    <div className=" space-y-3 font-semibold text-sm text-slate-600">
                      <div>PostBook: Poems & Quotes</div>
                      <div className=" gap-3 flex">
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-cyan-500 text-gray-100 font-normal text-sm">React</div>
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-blue-400 text-gray-100 font-normal text-sm">Tailwind</div>
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-emerald-800 text-gray-100 font-normal text-sm">Rest API</div>
                      </div>

                      {/* start of project cover */}
                      <div className=" w-[350px] relative block group">
                        <img className=" m-auto absolute inset-0 w-[350px] group-hover:opacity-20" src={PostBook} alt="" />

                        <div className="relative">
                          {/* hidden content */}
                          <div className="transition-all transform
                                  translate-y-8 opacity-0
                                  group-hover:opacity-100
                                  group-hover:translate-y-0
                                  duration-500
                                  ">
                            <div className=" p-20">
                              <div className=" gap-1 flex-col flex pt-4">
                                <a rel="noopener norefferer" target="_blank" href="https://post-book-uean.netlify.app/">
                                  <button className=" font-normal transition-all duration-200 hover:bg-cyan-600 text-gray-100 w-full m-auto bg-gray-700 py-1 px-2 rounded-full">Demo</button>
                                </a>
                                <a rel="noopener norefferer" target="_blank" href="https://github.com/ue-an/postbook-uean">
                                  <button className=" font-normal transition-all duration-200 hover:opacity-70 text-gray-100 w-full m-auto bg-gray-700 py-1 px-2 rounded-full">Code</button>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fade>
              }
            </div>

            <div ref={containerRefCell4} className=" space-y-3 py-3 m-auto grid">
              {
                isVisibleCell4 ? <Fade bottom duration={1000} opposite when={isVisibleCell4} distance="10%">
                  <div className=" space-y-3 font-semibold text-sm text-slate-600">
                    <div>SVRTV Database</div>
                    <div className=" pb-1 gap-3 flex">
                      <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-blue-900 text-gray-100 font-normal text-sm">PHP</div>
                      <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-red-500 text-gray-100 font-normal text-sm">Laravel</div>
                      <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-violet-500 text-gray-100 font-normal text-sm">Bootstrap</div>
                      <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-orange-500 text-gray-100 font-normal text-sm">MySQL</div>
                    </div>


                    {/* start of project cover */}
                    <div className=" w-[350px] relative block group">
                      <img className=" m-auto absolute inset-0 group-hover:opacity-20 w-[350px]" src={SVRTV} alt="" />

                      <div className="relative">
                        {/* Hidden content */}
                        <div className="transition transform
                                  translate-y-8 opacity-0
                                  group-hover:opacity-100
                                  group-hover:translate-y-0
                                  duration-500"
                        >
                          <div className=" p-20">
                            <div className=" pt-4">
                              <a rel="noopener noreferrer" target="_blank" href="https://github.com/ue-an/svrtv-database-laravel.git">
                                <button className=" duration-200 transition-all hover:bg-cyan-600 w-full py-1 px-2 rounded-full bg-gray-700 text-gray-100 font-normal m-auto">Code</button>
                              </a>
                            </div>
                          </div>
                        </div>
                        {/* End of hidden content */}
                      </div>
                    </div>
                    {/* end of start project cover */}
                  </div>
                </Fade> :
                  <Fade top duration={1000} opposite when={isVisibleCell4} distance="10%">
                    <div className=" space-y-3 font-semibold text-sm text-slate-600">
                      <div>SVRTV Database</div>
                      <div className=" pb-1 gap-3 flex">
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-blue-900 text-gray-100 font-normal text-sm">PHP</div>
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-red-500 text-gray-100 font-normal text-sm">Laravel</div>
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-violet-500 text-gray-100 font-normal text-sm">Bootstrap</div>
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-orange-500 text-gray-100 font-normal text-sm">MySQL</div>
                      </div>


                      {/* start of project cover */}
                      <div className=" w-[350px] relative block group">
                        <img className=" m-auto absolute inset-0 group-hover:opacity-20 w-[350px]" src={SVRTV} alt="" />

                        <div className="relative">
                          {/* Hidden content */}
                          <div className="transition transform
                                  translate-y-8 opacity-0
                                  group-hover:opacity-100
                                  group-hover:translate-y-0
                                  duration-500"
                          >
                            <div className=" p-20">
                              <div className=" pt-4">
                                <a rel="noopener noreferrer" target="_blank" href="https://github.com/ue-an/svrtv-database-laravel.git">
                                  <button className=" duration-200 transition-all hover:opacity-70 w-full py-1 px-2 rounded-full bg-gray-700 text-gray-100 font-normal m-auto">Code</button>
                                </a>
                              </div>
                            </div>
                          </div>
                          {/* End of hidden content */}
                        </div>
                      </div>
                      {/* end of start project cover */}
                    </div>
                  </Fade>
              }
            </div>

            <div ref={containerRefCell5} className=" space-y-3 py-3 m-auto grid">
              {
                isVisibleCell5 ? <Fade bottom duration={1000} opposite when={isVisibleCell5} distance="10%">
                  <div className=" space-y-3 font-semibold text-sm text-slate-600">
                    <div>SVRTV Prototype Database</div>
                    <div className=" gap-3 flex">
                      <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-blue-900 text-gray-100 font-normal text-sm">PHP</div>
                      <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-[#FFB200] text-gray-100 font-normal text-sm">JavaScript</div>
                      <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-[#F04A00] text-gray-100 font-normal text-sm">HTML</div>
                      <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-[#0b5a7e] text-gray-100 font-normal text-sm">CSS</div>
                      <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-orange-500 text-gray-100 font-normal text-sm">MySQL</div>
                    </div>

                    {/* start of project cover */}
                    <div className=" w-[350px] relative block group">
                      <img className=" m-auto absolute inset-0 w-[350px] group-hover:opacity-20" src={SVRTVdemo} alt="" />

                      <div className="relative">
                        {/* hidden content */}
                        <div className="transition-all transform
                                    translate-y-8 opacity-0
                                    group-hover:opacity-100
                                    group-hover:translate-y-0
                                    duration-500
                                    ">
                          <div className=" p-20">
                            <div className=" gap-1 flex-col flex pt-4">
                              <a rel="noopener norefferer" target="_blank" href="https://prototype-svrtv-database.online/">
                                <button className=" font-normal transition-all duration-200 hover:bg-cyan-600 text-gray-100 w-full m-auto bg-gray-700 py-1 px-2 rounded-full">Demo</button>
                              </a>
                              <a rel="noopener norefferer" target="_blank" href="https://github.com/ue-an/demo-svrtv-db-php-js-html-css">
                                <button className=" font-normal transition-all duration-200 hover:bg-cyan-600 text-gray-100 w-full m-auto bg-gray-700 py-1 px-2 rounded-full">Code</button>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fade> :
                  <Fade top duration={1000} opposite when={isVisibleCell5} distance="10%">
                    <div className=" space-y-3 font-semibold text-sm text-slate-600">
                      <div>SVRTV Prototype Database</div>
                      <div className=" gap-3 flex">
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-blue-900 text-gray-100 font-normal text-sm">PHP</div>
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-[#FFB200] text-gray-100 font-normal text-sm">JavaScript</div>
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-[#F04A00] text-gray-100 font-normal text-sm">HTML</div>
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-[#0b5a7e] text-gray-100 font-normal text-sm">CSS</div>
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-orange-500 text-gray-100 font-normal text-sm">MySQL</div>
                      </div>

                      {/* start of project cover */}
                      <div className=" w-[350px] relative block group">
                        <img className=" m-auto absolute inset-0 w-[350px] group-hover:opacity-20" src={SVRTVdemo} alt="" />

                        <div className="relative">
                          {/* hidden content */}
                          <div className="transition-all transform
                                    translate-y-8 opacity-0
                                    group-hover:opacity-100
                                    group-hover:translate-y-0
                                    duration-500
                                    ">
                            <div className=" p-20">
                              <div className=" gap-1 flex-col flex pt-4">
                                <a rel="noopener norefferer" target="_blank" href="https://prototype-svrtv-database.online/">
                                  <button className=" font-normal transition-all duration-200 hover:bg-cyan-600 text-gray-100 w-full m-auto bg-gray-700 py-1 px-2 rounded-full">Demo</button>
                                </a>
                                <a rel="noopener norefferer" target="_blank" href="https://github.com/ue-an/demo-svrtv-db-php-js-html-css">
                                  <button className=" font-normal transition-all duration-200 hover:bg-cyan-600 text-gray-100 w-full m-auto bg-gray-700 py-1 px-2 rounded-full">Code</button>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fade>
              }
            </div>

            <div ref={containerRefCell6} className=" space-y-3 py-3 m-auto grid">
              {
                isVisibleCell6 ? <Fade bottom duration={1000} opposite when={isVisibleCell6} distance="10%">
                  <div className=" space-y-3 font-semibold text-sm text-slate-600">
                    <div>
                      <div >UsApp: A Mobile Messaging Application for the</div>
                      <div>University of Rizal System Binangonan Campus</div>
                    </div>
                    <div className="gap-3 flex">
                      <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-violet-800 text-gray-100 font-normal text-sm">Dart</div>
                      <div className=" drop-shadow-lg rounded-full py-1 px-2 font-normal text-gray-100 dark:bg-cyan-700 bg-blue-500 text-sm">Flutter</div>
                      <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-yellow-500 text-gray-100 font-normal text-sm">Firebase</div>
                    </div>

                    {/* start of project cover */}
                    <div className=" w-[350px] relative block group">
                      <img className=" m-auto absolute inset-0 w-[350px] group-hover:opacity-20" src={Usapp} alt="" />

                      <div className="relative">
                        {/* Hidden content */}
                        <div className="transition-all transform
                                translate-y-8 opacity-0
                                group-hover:opacity-100
                                group-hover:translate-y-0
                                duration-500
                                ">
                          <div className=" p-20">
                            <div className=" gap-1 flex-col flex pt-4">
                              <a rel="noopener norefferer" target="_blank" href="https://github.com/ue-an/usapp-mobile-app">
                                <button className=" font-normal transition-all duration-200 hover:bg-cyan-600 text-gray-100 w-full m-auto bg-gray-700 py-1 px-2 rounded-full">Code</button>
                              </a>
                              <a rel="noopener norefferer" target="_blank" href="https://usapp-download-site.web.app/">
                                <button className=" font-normal transition-all duration-200 hover:bg-cyan-600 text-gray-100 w-full m-auto bg-gray-700 py-1 px-2 rounded-full">Download App</button>
                              </a>
                            </div>
                          </div>
                        </div>
                        {/* End of hidden content */}
                      </div>
                    </div>
                    {/* end of project cover */}
                  </div>
                </Fade> :
                  <Fade top duration={1000} opposite when={isVisibleCell6} distance="10%">
                    <div className=" space-y-3 font-semibold text-sm text-slate-600">
                      <div>
                        <div >UsApp: A Mobile Messaging Application for the</div>
                        <div>University of Rizal System Binangonan Campus</div>
                      </div>
                      <div className="gap-3 flex">
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-violet-800 text-gray-100 font-normal text-sm">Dart</div>
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 font-normal text-gray-100 dark:bg-cyan-700 bg-blue-500 text-sm">Flutter</div>
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-yellow-500 text-gray-100 font-normal text-sm">Firebase</div>
                      </div>

                      {/* start of project cover */}
                      <div className=" w-[350px] relative block group">
                        <img className=" m-auto absolute inset-0 w-[350px] group-hover:opacity-20" src={Usapp} alt="" />

                        <div className="relative">
                          {/* Hidden content */}
                          <div className="transition-all transform
                                translate-y-8 opacity-0
                                group-hover:opacity-100
                                group-hover:translate-y-0
                                duration-500
                                ">
                            <div className=" p-20">
                              <div className=" gap-1 flex-col flex pt-4">
                                <a rel="noopener norefferer" target="_blank" href="https://github.com/ue-an/usapp-mobile-app">
                                  <button className=" font-normal transition-all duration-200 hover:bg-cyan-600 text-gray-100 w-full m-auto bg-gray-700 py-1 px-2 rounded-full">Code</button>
                                </a>
                                <a rel="noopener norefferer" target="_blank" href="https://usapp-download-site.web.app/">
                                  <button className=" font-normal transition-all duration-200 hover:opacity-70 text-gray-100 w-full m-auto bg-gray-700 py-1 px-2 rounded-full">Download App</button>
                                </a>
                              </div>
                            </div>
                          </div>
                          {/* End of hidden content */}
                        </div>
                      </div>
                      {/* end of project cover */}
                    </div>
                  </Fade>
              }
            </div>

            <div ref={containerRefCell7} className=" space-y-3 py-3 m-auto grid">
              {
                isVisibleCell7 ? <Fade bottom duration={1000} opposite when={isVisibleCell7} distance="10%">
                  <div className="space-y-3 font-semibold text-sm text-slate-600">
                    <div>Kugo Runner</div>
                    <div className=" gap-3 flex">
                      <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-teal-500  text-gray-100 font-normal text-sm">C #</div>
                      <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-black text-gray-100 font-normal text-sm">Unity</div>
                    </div>

                    {/* start of project cover */}
                    <div className=" w-[350px] relative block group">
                      <img className=" m-auto absolute inset-0 group-hover:opacity-20 w-[350px]" src={KugoRunner} alt="" />

                      <div className="relative">
                        {/* Hidden content */}
                        <div className="transition-all transform
                                  translate-y-8 opacity-0
                                  group-hover:opacity-100
                                  group-hover:translate-y-0
                                  duration-500"
                        >
                          <div className=" p-20">
                            <div className=" gap-1 flex flex-col pt-4">
                              <a rel="noopener noreferrer" target="_blank" href="https://github.com/ue-an/Kugo-Runner-2D">
                                <button className=" duration-200 transition-all hover:bg-cyan-600 w-full py-1 px-2 rounded-full bg-gray-700 text-gray-100 font-normal m-auto">Code</button>
                              </a>
                              <button onClick={downloadKugoRunner} className=" duration-200 transition-all hover:bg-cyan-600 w-full py-1 px-2 rounded-full bg-gray-700 text-gray-100 font-normal m-auto">Download App</button>
                            </div>
                          </div>
                        </div>
                        {/* End of hidden content */}
                      </div>
                    </div>
                    {/* end of project cover */}
                  </div>
                </Fade> :
                  <Fade top duration={1000} opposite when={isVisibleCell7} distance="10%">
                    <div className="space-y-3 font-semibold text-sm text-slate-600">
                      <div>Kugo Runner</div>
                      <div className=" gap-3 flex">
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-teal text-gray-100 font-normal text-sm">C #</div>
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-black text-gray-100 font-normal text-sm">Unity</div>
                      </div>

                      {/* start of project cover */}
                      <div className=" w-[350px] relative block group">
                        <img className=" m-auto absolute inset-0 group-hover:opacity-20 w-[350px]" src={KugoRunner} alt="" />

                        <div className="relative">
                          {/* Hidden content */}
                          <div className="transition-all transform
                                  translate-y-8 opacity-0
                                  group-hover:opacity-100
                                  group-hover:translate-y-0
                                  duration-500"
                          >
                            <div className=" p-20">
                              <div className=" gap-1 flex flex-col pt-4">
                                <a rel="noopener noreferrer" target="_blank" href="https://github.com/ue-an/Kugo-Runner-2D">
                                  <button className=" duration-200 transition-all hover:opacity-70 w-full py-1 px-2 rounded-full bg-gray-700 text-gray-100 font-normal m-auto">Code</button>
                                </a>
                                <button onClick={downloadKugoRunner} className=" duration-200 transition-all hover:opacity-70 w-full py-1 px-2 rounded-full bg-gray-700 text-gray-100 font-normal m-auto">Download App</button>
                              </div>
                            </div>
                          </div>
                          {/* End of hidden content */}
                        </div>
                      </div>
                      {/* end of project cover */}
                    </div>
                  </Fade>
              }
            </div>

            <div ref={containerRefCell8} className=" space-y-3 py-3 m-auto grid">
              {
                isVisibleCell8 ? <Fade bottom duration={1000} opposite when={isVisibleCell8} distance="10%">
                  <div className=" space-y-3 font-semibold text-sm text-slate-600">
                    <div>
                      <div>LetCare: A Hydroponics Lettuce Gardening Monitoring</div>
                      <div>Mobile Application</div>
                    </div>
                    <div className="pb-1 gap-3 flex">
                      <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-violet-800 text-gray-100 font-normal text-sm">Dart</div>
                      <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-blue-500 text-gray-100 font-normal text-sm">Flutter</div>
                      <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-purple-800 text-gray-100 font-normal text-sm">Flutter Hive</div>
                    </div>

                    <div className=" w-[350px] relative block group">
                      {/* start of project cover */}
                      <img className=" w-[350px] m-auto absolute inset-0 group-hover:opacity-20 " src={Letcare} alt="" />

                      <div className="relative">
                        {/* Hidden content  */}
                        <div className=" transition-all transform
                                  translate-y-8 opacity-0
                                  group-hover:opacity-100
                                  group-hover:translate-y-0
                                  duration-500"
                        >
                          <div className=" p-20">
                            <div className=" pt-4">
                              <button onClick={downloadLetCare} className=" duration-200 transition-all font-normal hover:bg-cyan-600 w-full text-gray-100 bg-gray-700 py-1 px-2 rounded-full" >Download App</button>
                            </div>
                          </div>
                        </div>
                        {/* End of hidden content */}
                      </div>
                      {/* end of project cover */}
                    </div>
                  </div>
                </Fade> :
                  <Fade top duration={1000} opposite when={isVisibleCell8} distance="10%">
                    <div className=" space-y-3 font-semibold text-sm text-slate-600">
                      <div>
                        <div>LetCare: A Hydroponics Lettuce Gardening Monitoring</div>
                        <div>Mobile Application</div>
                      </div>
                      <div className="pb-1 gap-3 flex">
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-violet-800 text-gray-100 font-normal text-sm">Dart</div>
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-blue-500 text-gray-100 font-normal text-sm">Flutter</div>
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-purple-700 text-gray-100 font-normal text-sm">Flutter Hive</div>
                      </div>

                      <div className=" w-[350px] relative block group">
                        {/* start of project cover */}
                        <img className=" w-[350px] m-auto absolute inset-0 group-hover:opacity-20 " src={Letcare} alt="" />

                        <div className="relative">
                          {/* Hidden content  */}
                          <div className=" transition-all transform
                                  translate-y-8 opacity-0
                                  group-hover:opacity-100
                                  group-hover:translate-y-0
                                  duration-500"
                          >
                            <div className=" p-20">
                              <div className=" pt-4">
                                <button onClick={downloadLetCare} className=" duration-200 transition-all font-normal hover:opacity-70 w-full text-gray-100 bg-gray-700 py-1 px-2 rounded-full" >Download App</button>
                              </div>
                            </div>
                          </div>
                          {/* End of hidden content */}
                        </div>
                        {/* end of project cover */}
                      </div>
                    </div>
                  </Fade>
              }
            </div>

            <div ref={containerRefCell9} className=" space-y-3 py-3 m-auto grid">
              {
                isVisibleCell9 ?
                  <Fade bottom duration={1000} opposite when={isVisibleCell9} distance="10%">
                    <div className=" space-y-3 font-semibold text-sm text-slate-600">
                      <div>YourAnimeList: Anime Episodes Tracking Site</div>
                      <div className=" pb-1 gap-3 flex">
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-blue-900 text-gray-100 font-normal text-sm">PHP</div>
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-red-500 text-gray-100 font-normal text-sm">Laravel</div>
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-violet-500 text-gray-100 font-normal text-sm">Bootstrap</div>
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-orange-500 text-gray-100 font-normal text-sm">MySQL</div>
                      </div>

                      {/* start of project cover */}
                      <div className=" w-[350px] relative block group">
                        <img className=" m-auto absolute inset-0 w-[350px] group-hover:opacity-20" src={YourAniList} alt="" />

                        <div className="relative">
                          {/* Hidden content */}
                          <div className=" transition-all transform
                                    translate-y-8 opacity-0
                                    group-hover:opacity-100
                                    group-hover:translate-y-0
                                    duration-500
                                    "
                          >
                            <div className=" p-20">
                              <div className=" gap-1 flex flex-col">
                                <a rel="noopener norefferer" target="_blank" href="https://youranilist.online/">
                                  <button className=" duration-200 transition-all font-normal hover:bg-cyan-600 w-full text-gray-100 bg-gray-700 py-1 px-2 rounded-full" >Demo</button>
                                </a>
                                <a rel="noopener norefferer" target="_blank" href="https://github.com/ue-an/your-ani-list">
                                  <button className=" duration-200 transition-all hover:bg-cyan-600 text-gray-100 w-full bg-gray-700 py-1 px-2 rounded-full font-normal" >Code</button>
                                </a>
                              </div>
                            </div>
                          </div>
                          {/* End of hidden content */}
                        </div>
                      </div>
                      {/* end of priject cover */}
                    </div>
                  </Fade> :
                  <Fade top duration={1000} opposite when={isVisibleCell9} distance="10%">
                    <div className="space-y-3 font-semibold text-sm text-slate-600">
                      <div>YourAnimeList: Anime Episodes Tracking Site</div>
                      <div className=" pb-1 gap-3 flex">
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-blue-900 text-gray-100 font-normal text-sm">PHP</div>
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-red-500 text-gray-100 font-normal text-sm">Laravel</div>
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-violet-500 text-gray-100 font-normal text-sm">Bootstrap</div>
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-orange-500 text-gray-100 font-normal text-sm">MySQL</div>
                      </div>

                      {/* start of project cover */}
                      <div className=" w-[350px] relative block group">
                        <img className=" m-auto absolute inset-0  w-[350px] group-hover:opacity-20" src={YourAniList} alt="" />

                        <div className="relative">
                          {/* Hidden content */}
                          <div className=" transition-all transform
                                    translate-y-8 opacity-0
                                    group-hover:opacity-100
                                    group-hover:translate-y-0
                                    duration-500
                                    "
                          >
                            <div className=" p-20">
                              <div className=" gap-1 flex flex-col">
                                <a rel="noopener norefferer" target="_blank" href="https://youranilist.online/">
                                  <button className=" duration-200 transition-all font-normal hover:opacity-70 w-full text-gray-100 bg-gray-700 py-1 px-2 rounded-full" >Demo</button>
                                </a>
                                <a rel="noopener norefferer" target="_blank" href="https://github.com/ue-an/your-ani-list">
                                  <button className=" duration-200 transition-all hover:opacity-70 text-gray-100 w-full bg-gray-700 py-1 px-2 rounded-full font-normal" >Code</button>
                                </a>
                              </div>
                            </div>
                          </div>
                          {/* End of hidden content */}
                        </div>
                      </div>
                      {/* end of priject cover */}
                    </div>
                  </Fade>
              }
            </div>

            <div ref={containerRefCell10} className=" space-y-3 py-3 m-auto grid">
              {
                isVisibleCell10 ? <Fade bottom duration={1000} opposite when={isVisibleCell10} distance="10%">
                  <div className=" pt-4 space-y-3 font-semibold text-sm text-slate-600">
                    <div>AniWorld: Anime List Browsing Website</div>
                    <div className=" pb-1 flex">
                      <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-green-600 text-gray-100 font-normal text-sm">Google Sites</div>
                    </div>
                    <div>
                      {/* start of project cover */}
                      <div className=" w-[350px] relative block group">
                        <img className=" m-auto absolute inset-0 w-[350px] group-hover:opacity-20" src={AniWorld} alt="" />

                        <div className="relative">
                          {/* Hidden content */}
                          <div className=" transition-all transform
                                        translate-y-8 opacity-0
                                        group-hover:opacity-100
                                        group-hover:translate-y-0
                                        duration-500"
                          >
                            <div className="p-20">
                              <div className=" pt-4">
                                <a rel="noopener norefferer" target="_blank" href="https://sites.google.com/view/ani-world/home">
                                  <button className=" font-normal transition-all duration-200 hover:bg-cyan-600 text-gray-100 w-full m-auto bg-gray-700 py-1 px-2 rounded-full">Demo</button>
                                </a>
                              </div>
                            </div>
                          </div>
                          {/* End of hidden content */}
                        </div>
                      </div>
                      {/* end of project cover */}
                    </div>
                  </div>
                </Fade> :
                  <Fade top duration={1000} opposite when={isVisibleCell10} distance="10%">
                    <div className=" pt-4 space-y-3 font-semibold text-sm text-slate-600">
                      <div>AniWorld: Anime List Browsing Website</div>
                      <div className=" pb-1 flex">
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-green-600 text-gray-100 font-normal text-sm">Google Sites</div>
                      </div>
                      <div>
                        {/* start of project cover */}
                        <div className=" w-[350px] relative block group">
                          <img className=" m-auto absolute inset-0 w-[350px] group-hover:opacity-20" src={AniWorld} alt="" />

                          <div className="relative">
                            {/* Hidden content */}
                            <div className=" transition-all transform
                                        translate-y-8 opacity-0
                                        group-hover:opacity-100
                                        group-hover:translate-y-0
                                        duration-500"
                            >
                              <div className="p-20">
                                <div className=" pt-4">
                                  <a rel="noopener norefferer" target="_blank" href="https://sites.google.com/view/ani-world/home">
                                    <button className=" font-normal transition-all duration-200 hover:opacity-70 text-gray-100 w-full m-auto bg-gray-700 py-1 px-2 rounded-full">Demo</button>
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/* End of hidden content */}
                          </div>
                        </div>
                        {/* end of project cover */}
                      </div>
                    </div>
                  </Fade>
              }
            </div>

            <div ref={containerRefCell11} className=" space-y-3 py-3 m-auto grid">
              {
                isVisibleCell11 ? <Fade bottom duration={1000} opposite when={isVisibleCell11} distance="10%">
                  <div className=" space-y-3 font-semibold text-sm text-slate-600">
                    <div>Mobile Image Recognition App</div>
                    <div className=" gap-3 flex">
                      <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-violet-800 text-gray-100 font-normal text-sm">Dart</div>
                      <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-blue-500 text-gray-100 font-normal text-sm">Flutter</div>
                      <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-orange-400 text-gray-100 font-normal text-sm">TensorFlow Lite</div>
                    </div>

                    {/* start of project cover */}
                    <div className=" w-[350px] relative block group">
                      <img className=" m-auto absolute inset-0 w-[350px] group-hover:opacity-20" src={ImgRecognition} alt="" />

                      <div className="relative">
                        {/* hidden content */}
                        <div className="transition-all transform
                                    translate-y-8 opacity-0
                                    group-hover:opacity-100
                                    group-hover:translate-y-0
                                    duration-500
                                    ">
                          <div className=" p-20">
                            <div className=" gap-1 flex-col flex pt-4">
                              <a rel="noopener norefferer" target="_blank" href="https://post-book-uean.netlify.app/">
                                <button className=" font-normal transition-all duration-200 hover:bg-cyan-600 text-gray-100 w-full m-auto bg-gray-700 py-1 px-2 rounded-full">Code</button>
                              </a>
                              <button onClick={downloadImgRecogFlutter} className=" font-normal transition-all duration-200 hover:opacity-70 text-gray-100 w-full m-auto bg-gray-700 py-1 px-2 rounded-full">Download App</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fade> :
                  <Fade top duration={1000} opposite when={isVisibleCell11} distance="10%">
                    <div className=" space-y-3 font-semibold text-sm text-slate-600">
                      <div>Mobile Image Recognition App</div>
                      <div className=" gap-3 flex">
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-violet-800 text-gray-100 font-normal text-sm">Dart</div>
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-blue-500 text-gray-100 font-normal text-sm">Flutter</div>
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-orange-400 text-gray-100 font-normal text-sm">TensorFlow Lite</div>
                      </div>

                      {/* start of project cover */}
                      <div className=" w-[350px] relative block group">
                        <img className=" m-auto absolute inset-0 w-[350px] group-hover:opacity-20" src={ImgRecognition} alt="" />

                        <div className="relative">
                          {/* hidden content */}
                          <div className="transition-all transform
                                    translate-y-8 opacity-0
                                    group-hover:opacity-100
                                    group-hover:translate-y-0
                                    duration-500
                                    ">
                            <div className=" p-20">
                              <div className=" gap-1 flex-col flex pt-4">
                                <a rel="noopener norefferer" target="_blank" href="https://post-book-uean.netlify.app/">
                                  <button className=" font-normal transition-all duration-200 hover:bg-cyan-600 text-gray-100 w-full m-auto bg-gray-700 py-1 px-2 rounded-full">Code</button>
                                </a>
                                <button onClick={downloadImgRecogFlutter} className=" font-normal transition-all duration-200 hover:opacity-70 text-gray-100 w-full m-auto bg-gray-700 py-1 px-2 rounded-full">Download App</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fade>
              }
            </div>

            <div ref={containerRefCell12} className=" space-y-3 py-3 m-auto grid">
              {
                isVisibleCell12 ? <Fade bottom duration={1000} opposite when={isVisibleCell12} distance="10%">
                  <div className=" space-y-3 font-semibold text-sm text-slate-600">
                    <div>Mobile Legends Training Guide</div>
                    <div className=" gap-3 flex">
                      <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-[#F04A00] text-gray-100 font-normal text-sm">HTML</div>
                      <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-[#0b5a7e] text-gray-100 font-normal text-sm">CSS</div>
                    </div>

                    {/* start of project cover */}
                    <div className=" w-[350px] relative block group">
                      <img className=" m-auto absolute inset-0 w-[350px] group-hover:opacity-20" src={MLTraining} alt="" />

                      <div className="relative">
                        {/* hidden content */}
                        <div className="transition-all transform
                                    translate-y-8 opacity-0
                                    group-hover:opacity-100
                                    group-hover:translate-y-0
                                    duration-500
                                    ">
                          <div className=" p-20">
                            <div className=" gap-1 flex-col flex pt-4">
                              <a rel="noopener norefferer" target="_blank" href="https://mobile-legends-heroes-guide.netlify.app/">
                                <button className=" font-normal transition-all duration-200 hover:bg-cyan-600 text-gray-100 w-full m-auto bg-gray-700 py-1 px-2 rounded-full">Demo</button>
                              </a>
                              <a rel="noopener norefferer" target="_blank" href="https://github.com/ue-an/mobile-legends-role-training-site">
                                <button className=" font-normal transition-all duration-200 hover:bg-cyan-600 text-gray-100 w-full m-auto bg-gray-700 py-1 px-2 rounded-full">Code</button>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fade> :
                  <Fade top duration={1000} opposite when={isVisibleCell12} distance="10%">
                    <div className=" space-y-3 font-semibold text-sm text-slate-600">
                      <div>Mobile Legends Training Guide</div>
                      <div className=" gap-3 flex">
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-[#F04A00] text-gray-100 font-normal text-sm">HTML</div>
                        <div className=" drop-shadow-lg rounded-full py-1 px-2 dark:bg-cyan-700 bg-[#0b5a7e] text-gray-100 font-normal text-sm">CSS</div>
                      </div>

                      {/* start of project cover */}
                      <div className=" w-[350px] relative block group">
                        <img className=" m-auto absolute inset-0 w-[350px] group-hover:opacity-20" src={MLTraining} alt="" />

                        <div className="relative">
                          {/* hidden content */}
                          <div className="transition-all transform
                                    translate-y-8 opacity-0
                                    group-hover:opacity-100
                                    group-hover:translate-y-0
                                    duration-500
                                    ">
                            <div className=" p-20">
                              <div className=" gap-1 flex-col flex pt-4">
                                <a rel="noopener norefferer" target="_blank" href="https://mobile-legends-heroes-guide.netlify.app/">
                                  <button className=" font-normal transition-all duration-200 hover:bg-cyan-600 text-gray-100 w-full m-auto bg-gray-700 py-1 px-2 rounded-full">Demo</button>
                                </a>
                                <a rel="noopener norefferer" target="_blank" href="https://github.com/ue-an/mobile-legends-role-training-site">
                                  <button className=" font-normal transition-all duration-200 hover:bg-cyan-600 text-gray-100 w-full m-auto bg-gray-700 py-1 px-2 rounded-full">Code</button>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fade>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;