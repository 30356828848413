import React, { useRef } from "react";
import PortfolioIcon from '../assets/port-icon3.png';
import Fire from "../assets/animations/flame-magic.json";
import Lottie, { LottieComponentProps } from "lottie-react";
import Wizard from "../assets/animations/wizard.json";
import MagicCircle from "../assets/magicCircle.png";
import Laptop from "../assets/animations/laptop-open.json";
import Keyboard from "../assets/animations/keyboard-typing-colors.json";
import Mouse from "../assets/animations/mouse-white.json";
import Type from "../assets/animations/typing-line-teal.json";

const Footer = () => {
  const toggleRef = useRef();

 return (
  <div className=" bg-black dark:bg-gray-800 shadow w-full transition-all duration-500">
       
          <div className=" grid-cols-1 md:grid-cols-2 grid mx-auto w-full max-w-[1080px] p-4">
            <div className=" justify-center md:justify-start flex col-span-1">
              <div className=" text-gray-500 text-sm flex-col">
                <div className="flex justify-start items-center">
                  <Lottie className="w-9"
                   onComplete={()=>{}}
                   lottieRef={toggleRef}
                   animationData={Keyboard}
                  />
                  <Lottie className="w-4"
                   onComplete={()=>{}}
                   lottieRef={toggleRef}
                   animationData={Mouse}
                  />
                  <p>&lt; Developer by day /&gt;</p>
                  <Lottie className="w-9"
                   onComplete={()=>{}}
                   lottieRef={toggleRef}
                   animationData={Type}
                  />
                </div>
                <div className="flex justify-center md:justify-start items-center">
                  <Lottie className=" w-[32px]"
                   onComplete={()=>{}}
                   lottieRef={toggleRef}
                   animationData={Wizard}
                   />
                  <img className=" rotate w-6" src={MagicCircle} alt="" />
                  <p className="text-gray-800">"</p>
                  <p>Sorcerer by night </p>
                  <Lottie className=" w-[32px]"
                   onComplete={()=>{}}
                   lottieRef={toggleRef}
                   animationData={Fire}
                   />
                </div>
              </div>
              
            </div>

            <div className=" justify-center md:justify-end flex col-span-1">
              <div className="flex-col">
                <div className=" pt-1 flex items-center">
                 <p className="text-sm text-gray-500 sm:text-center mr-2">© 2023 </p>
                 <img className="w-[20px] mr-1" src={PortfolioIcon} alt="" />
                 <p className="text-sm text-gray-500 sm:text-center">Dev Portfolio</p>
                </div>
                {/* <div>
                  <p className="text-primary">..</p>
                </div> */}
                <div className=" pt-3 flex justify-center md:justify-end">
                  <p className="text-sm text-gray-500 sm:text-center">All Rights Reserved</p>
                </div>
              </div>
            </div>
          </div>
       
   </div>
 );
}

export default Footer;