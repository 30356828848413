import React, { useRef } from "react";
import LCircuit from "../assets/animations/circuit-left.json";
import RCircuit from "../assets/animations/circuit-right.json";
import MagicCircle from "../assets/animations/magic-circle-accent.json";
import MagicCircleGold from "../assets/animations/magic-circle-palegold.json"
import Spiral from "../assets/animations/orb-spiral.json";
import Neuralink from "../assets/animations/neuralink.json";
import Neuralink2 from "../assets/animations/neuralink2.json";
import RingGray from "../assets/animations/ring-gray.json";
import Lottie from 'lottie-react';

const Backgrounds = ({darkMode}) => {
 const lcircuitRef = useRef();
 const rcircuitRef = useRef()
 const magicCircleRef = useRef()
 const magicCircleGoldRef = useRef()
 const spiralRef = useRef()
 const neuralinkRef = useRef()
 const neuralink2Ref = useRef()
 const ringGrayRef = useRef()


 return (
  <div>
    <div className=" z-0 flex m-auto justify-start">
        {!darkMode?
        <div className=" md:-left-20 -left-56 top-28 md:-top-20 z-0 fixed">
         <Lottie className=" w-[600px] opacity-30"
          onComplete={()=>{}}
          lottieRef={neuralink2Ref}
          animationData={Neuralink2}
         />
        </div> :
        <div className=" lg:-top-[43vh] lg:-left-44 opacity-30 -left-72 -top-56 z-0 fixed">
         <Lottie className=" opacity-50 lg:w-[900px] md:w-[700px] w-[600px]"
          onComplete={()=>{}}
          lottieRef={ringGrayRef}
          animationData={RingGray}
         />
        </div>
        }
    </div>

    <div className=" z-0 flex m-auto justify-end">
      {!darkMode?
        <div className=" top-[50vh] -right-20 md:-right-44 md:top-[70vh] z-0 fixed">
        <Lottie className=" w-[200px] md:w-[400px] opacity-20"
          onComplete={()=>{}}
          lottieRef={neuralinkRef}
          loop={false}
          animationData={Neuralink}
        />
        </div> :
        <div className=" opacity-30 -bottom-[30vh] -right-56 lg:-right-9 md:-right-44 md:-bottom-80 z-0 fixed">
          <Lottie className="md:w-[600px] w-[400px] opacity-60"
            onComplete={()=>{}}
            lottieRef={spiralRef}
            animationData={Spiral}
          />
        </div>
      }
      </div>

    <div className=" z-0 flex m-auto justify-end">
      {!darkMode ?
        <div className=" -rotate-45 md:-rotate-90 lg:top-0 lg:right-72 md:-top-9 md:right-0 -right-20 -top-0 z-0 fixed">
          <Lottie className=" w-[250px] md:w-[350px] opacity-50"
            onComplete={() => {
            }}
            lottieRef={rcircuitRef}
            animationData={RCircuit}
          /> 
        </div> :
        <div className=" md:bottom-[30vh] md:right-[-44vh] right-[-20vh] bottom-[65vh] z-0 fixed">
          <Lottie className="md:w-[600px] w-[400px] opacity-50"
            onComplete={()=> {
            }}
            lottieRef={magicCircleGoldRef}
            animationData={MagicCircleGold}
          />
        </div>
      }
    </div>
    
    <div className=" z-0 flex justify-start">
     {!darkMode ? 
     <div className=" -rotate-45 md:-rotate-90 lg:-bottom-20 lg:left-44 md:-bottom-0 md:-left-12 -bottom-0 -left-24 z-0 fixed">
       <Lottie className=" opacity-50 w-[250px] md:w-[400px]"
         onComplete={() => {}}
         lottieRef={lcircuitRef}
         animationData={LCircuit}
       />
      </div> : 
       <div className=" bottom-[3vh] left-[-40vh] md:left-[-60vh] z-0 fixed">
        <Lottie className=" opacity-30 md:w-[900px] w-[500px]"
          onComplete={() => {}}
          lottieRef={magicCircleRef}
          animationData={MagicCircle}
        />
       </div>
     }  
    </div>
  </div>
 );
}

export default Backgrounds;